<template>
    <div class="task">
        <el-affix :offset="55">
            <header>
                <span class="head-but" @click="goback"><el-button plain :icon="ArrowLeft">返回</el-button></span>
                <span class="head-t">{{ title }}</span>
            </header>
        </el-affix>
        <div class="form">
            <el-row>
                <el-col :span="22" class="audit-title">
                    <span class="text">重大任务基本信息</span>
                </el-col>
            </el-row>
            <IndexInfo :id="route.query.id" :formData.sync="formData" :disabled="true" />
            <el-row>
                <el-col :span="22" class="audit-title">
                    <span class="text">重大任务指标信息</span>
                </el-col>
                <el-col :span="22" class="table" v-if="route.query.title == '审核'">
                    <IndexAuditTable :id="route.query.id" ref="indexAuditRef" @auditSuccess="auditSuccess" />
                </el-col>
                <el-col :span="22" class="table" v-if="route.query.title == '查看重大任务'">
                    <AuditRaecords :id="route.query.id" :type="2" />
                </el-col>
                <el-col :span="22" class="table" v-if="route.query.title == '查看指标'">
                    <el-table
                        :data="tableData"
                        :default-sort="{ prop: 'createTime', order: 'descending' }"
                        style="width: 100%; height: 100%"
                        header-row-class-name="table-header"
                        :header-cell-style="{
                            background: '#F7F8FA',
                            color: '#333333',
                            height: '54px',
                            'font-size': '14px',
                        }"
                    >
                        <el-table-column type="index" width="70" label="序号" class-name="el-table-fixed-column--left" />
                        <el-table-column
                            v-for="item in tableColumns"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :formatter="item.formatter"
                            :show-overflow-tooltip="true"
                            :class-name="item.class"
                        >
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <el-row v-if="route.query.title !== '查看重大任务'">
                <el-col :span="22" class="audit-title">
                    <span class="text">审核记录</span>
                </el-col>
                <el-col :span="22" class="table">
                    <AuditRaecords :id="route.query.id" :type="4" />
                </el-col>
            </el-row>
        </div>
        <el-row :span="16" class="footer" v-if="title == '审核'">
            <el-button type="primary" v-if="frontTemplateNode.endVerifyFlag == 1" @click="auditItem(1)" :disabled="auditList.length < 1"
                >确认签出任务</el-button
            >
            <el-button type="primary" @click="auditItem(0)" :disabled="auditList.length < 1">{{
                frontTemplateNode.endVerifyFlag == 1 ? '提交常务审核' : '确认并签出'
            }}</el-button>
            <el-button type="primary" @click="saveAudit">暂 存</el-button>
            <el-button type="danger" @click="goback">取 消</el-button>
        </el-row>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineEmits, defineProps, getCurrentInstance, nextTick } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AuditRaecords from '@/components/auditRaecords';
import IndexInfo from '@/components/indexInfo';
import IndexAuditTable from '@/views/TaskReview/components/indexAuditTable';
import indexsConfig from '@/views/taskManagement/components/indexsConfig';
let store = useStore();
const { proxy } = getCurrentInstance();
let userRole = ref(false);
let rules = {
    indexName: [{ required: true, message: '请输入指标名称', trigger: 'blur' }],
    indexType: [{ required: true, message: '请选择是否有目标值', trigger: 'blur' }],
    indexNum: [{ required: true, message: '请填写目标值', trigger: 'blur' }],
    sourceName: [{ required: true, message: '请输入数源应用名称', trigger: 'blur' }],
    whetherSource: [{ required: true, message: '请选择是否有数源应用', trigger: 'blur' }],
    indexSecret: [{ required: true, message: '请选择指标是否涉密', trigger: 'blur' }],
    unitId: [{ required: true, message: '请选择责任单位', trigger: 'blur' }],
    indexType: [{ required: true, message: '请输入目标值', trigger: 'blur' }],
    noIndexType: [{ required: false, message: '请输入目标值', trigger: 'blur' }],
};
let roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    if (title.value == '编辑指标') {
        role.forEach(e => {
            if (roles.indexOf(e) > -1) {
                res = false;
            }
            return res;
        });
    } else {
        res = false;
    }
    return res;
}
let tableData = ref([]);
const leadOptions = ref([]);
const router = useRouter();
let formLabelWidth = '150px';
let tableColumns = computed(() => {
    let main = indexsConfig['indexColunm'];
    return main;
});
const options = [
    {
        value: '1',
        label: '每月',
    },
    {
        value: '2',
        label: '每季度',
    },
    {
        value: '3',
        label: '每半年',
    },
    {
        value: '4',
        label: '每年',
    },
];
// const defaultProps = {
//     lazy: true,
//     checkStrictly: true,
//     multiple: true,
//     async lazyLoad(node, resolve) {
//         // console.log(node,);
//         const { level } = node;
//         let data = level > 0 ? node.value : 'top';
//         level + 1;
//         let res = await axios({
//             url: 'front/sysOrganization/getPage',
//             method: 'get',
//             params: {
//                 uacDeptId: data,
//             },
//         }); //.then(  => {
//         if (res) {
//             let nodes = res.map(item => {
//                 return {
//                     label: item.deptName,
//                     value: item.uacDeptId,
//                     leaf: false,
//                     deptId: item.deptId,
//                 };
//             });
//             if (res.length == 0) {
//                 console.log(node);
//                 node.data.leaf = true;
//             }
//             resolve(nodes);
//         }
//         // });
//     },
// };
// 点击联选节点触发事件
function handleChange(index, unitIndex, val) {
    console.log(index, unitIndex);
}

let formData = ref({});
let frontTemplateNode = ref({});
let ruleForm = ref(null);
let showAuditReturn = ref(false);
let emit = defineEmits();
// let props = defineProps(['formData', 'title']);
// const { formData, title } = props;
// console.log(formData, title);
const route = useRoute();
let title = ref('');
let date = new Date();
let year = date.getFullYear();
let month = date.getMonth();
const form = ref({
    tagertSymbol: '1',
    unitList: [{}],
});
let auditList = ref([]);
let indexAuditRef = ref(null);
// 表单数据初始化
function init() {
    console.log(route);
    title.value = route.query.title;
    form.value.actionId = route.query.id;
}
const getIndexById = () => {
    //根据指标id获取指标详情
    route.query.indexId &&
        proxy.$api.frontIndex.getById(route.query.indexId).then(res => {
            if (res.code == 200) {
                form.value = res.data;
            }
        });
};
const getIndexesByActionId = () => {
    //根据id获取指标列表
    proxy.$api.frontIndex.getIndexesByActionId(route.query.id).then(res => {
        if (res.code == 200) {
            tableData.value = res.data;
            tableData.value.forEach(e => {
                e.indexTypeText = e.indexType ? '是' : '否';
                e.indexSecretText = e.indexSecret ? '是' : '否';
            });
        }
    });
};
const getTaskById = () => {
    //根据id获取任务详情
    console.log(route.query.title);
    let method = route.query.title === '查看指标' ? 'getReviewedZbById' : 'getReviewedById'
    proxy.$api.frontAction[method](route.query.id).then(res => {
        if (res.code == 200) {
            formData.value = res.data;
            frontTemplateNode.value = res.data.frontTemplateNode;
        }
    });
};
function getOption() {
    axios({
        url: '/system/dict/data/list',
        method: 'get',
        params: {
            dictType: 'lead_dept',
        },
    }).then(res => {
        axios({
            url: '/system/dept/getUserByDeptId',
            method: 'get',
            params: {
                deptId: res[0].dictValue,
            },
        }).then(ret => {
            leadOptions.value = ret;
        });
    });
}
function auditSuccess(list) {
    auditList.value = list;
}
// 确认并签出
const auditItem = status => {
    nextTick(() => {
        let { actionId, currentNodeId } = formData.value;
        let auditData = {
            actionId: actionId,
            currentNodeId: currentNodeId,
            frontIndexList: auditList.value,
        };
        auditData.endVerify = status || 0;
        console.log(auditData);
        indexAuditRef.value
            .submit()
            .then(res => {
                res &&
                    proxy.$api.frontVerify.verify(auditData).then(res => {
                        if (res.code == 200) {
                            ElMessage.success('成功');
                            router.push({
                                path: 'taskReview',
                            });
                        } else {
                            ElMessage.error(res.msg);
                        }
                    });
            })
            .catch(err => {
                console.log(err);
            });
    });
};
// 保存审核
function saveAudit() {
    if (auditList.value.length < 1) {
        ElMessage.warning('没有要保存的数据');
        return;
    }
    proxy.$api.frontIndex.updateList(auditList.value).then(res => {
        if (res.code == 200) {
            ElMessage.success('成功');
            router.push({
                path: 'taskReview',
            });
        } else {
            ElMessage.error(res.msg);
        }
    });
}
// 审核退回
const auditReturn = async ruleForm => {
    showAuditReturn.value = true;
};
function goback() {
    let tab;
    let path;
    switch (title.value) {
        case '审核':
            path = '/taskReview';
            tab = 1;
            break;
        case '查看重大任务':
            path = '/taskReview';
            tab = 2;
            break;
        case '查看指标':
            path = '/taskReview';
            tab = 3;
            break;
    }
    router.push({
        path: path,
        query: {
            tab: tab,
            id: form.value.actionId,
        },
    });
    // 1.查看指标 type1;  新增指标； 编辑指标
    // 2. （1） 审核 type=4； （2） 查看重大任务 type=2；（3） 查看已审核指标 type1
}
onMounted(() => {
    getTaskById();
    // getIndexById();
    getIndexesByActionId();
    // getOption();
    init();
    let formBox = document.getElementsByClassName('form');
    if (route.query.title.indexOf('查看') > -1) {
        formBox[0].style.height = 'calc(100% - ' + 100 + 'px)';
    }
});
</script>

<style scoped lang="stylus">
.task
    height: calc(100% - 32px);
    header
        // width: 1200px;
        height: 56px;
        line-height: 56px
        text-align: center
        background: #FFFFFF;
        box-shadow: 0 0 10px -6px rgba(0,0,0,0.30);
        border-radius: 4px;
        padding-left 24px
        border-bottom: 1px solid #EAEAEA
        .head-but
            float left
        .head-t
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
    .title
        text-align: left
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 16px
    .add-unit
        margin-bottom 24px
        color: #1492FF;
        text-align center
    .unit-input
        position relative
    .icon
        width: 16px;
        height: 16px;
        position absolute
        top 8px
        right -25px
        background: url('~@/assets/img/delete.png') no-repeat
    .tagertNum
        /deep/ .el-form-item__content
            flex-wrap: nowrap
            justify-content: space-between
    .table
        margin-bottom 16px
/deep/ .el-cascader
    width 39.2vw
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-row
    justify-content center
</style>
